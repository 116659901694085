import { filter } from "lodash";
import { useState, useRef, useEffect } from "react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
// material
import {
  Box,
  Link,
  Breadcrumbs,
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ReactToPrint from "react-to-print";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";

// components
import SearchNotFound from "../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  PatientMoreMenu,
} from "../../sections/user";
//
// import USERLIST from '../../_mocks_/user';
import { useSelector } from "react-redux";
// ----------------------------------------------------------------------

//date fns
import { format } from "date-fns";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../utils/firebase";
const TABLE_HEAD = [
  { id: "category", label: "Category", alignRight: false },
  { id: "ContactNumber", label: "Contact Number", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "Created", label: "Created", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.Category.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function RegularList() {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const USERLIST = user.patients.filter(
    (patients) => patients.Category === "Regular Checkup"
  );
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState({});
  const [orderBy, setOrderBy] = useState("FullName");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const componentRef = useRef();

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "Patients", id), (doc) => {
      setSelectedUserData(doc.data());
    });
    return unsub;
  }, [id]);
  const newUSERLIST = USERLIST.filter(
    (n) => n.FullName === selectedUserData.FullName
  ).map((n) => n);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = newUSERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newUSERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    newUSERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Container sx={{ mt: 12, mb: 5 }}>
        <Box  display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            <Box gridColumn="span 9" direction="column" alignItems="center">
              
              <Breadcrumbs
                sx={{ ml: 0.5 }}
                separator={
                  <Box
                    sx={{
                      width: 4,
                      height: 4,
                      bgcolor: "gray",
                      borderRadius: "50%",
                      mx: 1,
                    }}
                  />
                }
              >
                
              </Breadcrumbs>
            </Box>
            <Box
              gridColumn="span 3"
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box gridColumn="span 3">
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="contained"
                      startIcon={<LocalPrintshopOutlinedIcon />}
                    >
                      Print
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
              </Box>
            </Box>
          </Box>
      <Container ref={componentRef}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Box  display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            <Box gridColumn="span 9" direction="column" alignItems="center">
              <Typography variant="h4" gutterBottom>
                {selectedUserData.Category} Record - <br />{" "}
                {selectedUserData.FullName}
              </Typography>
              <Breadcrumbs
                sx={{ ml: 0.5 }}
                separator={
                  <Box
                    sx={{
                      width: 4,
                      height: 4,
                      bgcolor: "gray",
                      borderRadius: "50%",
                      mx: 1,
                    }}
                  />
                }
              >
                <Link
                  component={RouterLink}
                  underline="hover"
                  color="inherit"
                  to="/listregular"
                >
                  <Typography color="text.primary" variant="body2">
                    Patient
                  </Typography>
                </Link>
                <Typography color="gray" variant="body2">
                  List
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box
              gridColumn="span 3"
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
            >
            </Box>
          </Box>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={newUSERLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      id,
                      UserName,
                      Created,
                      FullName,
                      Category,
                      ResidentAddress,
                      ContactNumber,
                    } = row;
                    const isItemSelected = selected.indexOf(UserName) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selected.includes(id)}
                            onChange={(event) => handleClick(event, id)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {/* <Avatar alt={UserName} src={Image} /> */}
                          <Typography variant="subtitle2" noWrap>
                            {Category}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{ContactNumber}</TableCell>
                        <TableCell align="left">{ResidentAddress}</TableCell>
                        <TableCell align="left">
                          {format(
                            new Date(Created.seconds * 1000),
                            "MM/dd/yyyy"
                          )}{" "}
                        </TableCell>
                        <TableCell align="right">
                          {Category !== "Record" && (
                            <PatientMoreMenu id={id} category={Category} />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={newUSERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Container>
  );
}

export default RegularList;
