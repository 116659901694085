import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { Switch, Route, Redirect } from "react-router-dom";
import { Box, Snackbar, Alert } from "@mui/material";
import theme from "../utils/theme";
import Loading from "../components/Loading";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";

//user
import ListUser from "../pages/users/List";
import CreateUser from "../pages/users/Create";
import EditUser from "../pages/users/Edit";
// patient
import ListPatient from "../pages/patients/List";
import CreatePatient from "../pages/patients/Create";
import EditPatient from "../pages/patients/Edit";
// appointment
import ListAppointment from "../pages/appointments/List";
import CreateAppointment from "../pages/appointments/Create";
import EditAppointment from "../pages/appointments/Edit";
// medicine
import ListMedicine from "../pages/medicines/List";
import CreateMedicine from "../pages/medicines/Create";
import EditMedicine from "../pages/medicines/Edit";

// news and blogs
import ListNews from "../pages/newsBlogs/List";
import CreateNews from "../pages/newsBlogs/Create";
import EditNews from "../pages/newsBlogs/Edit";
import Order from "../pages/Order";
import Login from "../pages/Login";
import Sidebar from "../components/Sidebar";
import Dashboard from "../pages/Dashboard";
import NotFound from "../pages/404";
import History from "../pages/History";

//report
import Regular from "../pages/report/Regular";
import Family from "../pages/report/Family";
import Prenatal from "../pages/report/Prenatal";
import Immunization from "../pages/report/Immunization";
import Availment from "../pages/report/Availment";

//backend
import { onAuthStateChanged } from "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { auth, db } from "../utils/firebase";
import { getTheme, getLang } from "../redux/actions/uiAction";
import {
  where,
  collection,
  query,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

import {
  setMyData,
  setUsers,
  setMedicines,
  setNews,
  setPatients,
  setHistory,
  setAppointment,
} from "../redux/actions/userAction";
import ForgotPassword from "../pages/ForgotPassword";
import PatientRecord from "../pages/patients/PatientRecord";
import AcceptedAppoint from "../pages/appointments/AcceptedAppoint";
import CancelAppoint from "../pages/appointments/CancelAppoint";
import DoneAppoint from "../pages/appointments/DoneAppoint";
import Familylist from "../pages/report/FamilyList";
import RegularList from "../pages/report/RegularList";
import PrenatalList from "../pages/report/PrenatalList";
import ImmunizationList from "../pages/report/ImmunizationList";
import AvailmentList from "../pages/report/AvailmentList";

export default function Index() {
  // const [snackbarOpen, setSnackbarOpen] = useState(false);

  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const THEME = createTheme(theme(ui.isDarkMode));
  // const location = useLocation();

  const [state, setstate] = useState({
    isAuth: false,
    isLoading: true,
  });

  //snackbar
  const [stateSnap, setStateSnap] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setStateSnap({ ...stateSnap, open: false });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      // console.log(user.uid);

      if (user && user.emailVerified) {
        const collectionRefMyData = collection(db, "Users");
        const MyData = query(
          collectionRefMyData,
          where("UserUid", "==", user.uid)
        );

        dispatch(setMyData());
        onSnapshot(MyData, (snapshot) => {
          if (
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0]
              .UserType === "Admin"
          ) {
            dispatch(
              setMyData(
                snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
              )
            );

            setstate({ isAuth: true, isLoading: false });
          } else if (
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0]
              .UserType === "Admin"
          ) {
            dispatch(
              setMyData(
                snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
              )
            );

            setstate({ isAuth: true, isLoading: false });
          } else {
            setstate({ isAuth: false, isLoading: false });
            auth.signOut();
            // alert("Your Account is not Admin");
            console.log("Your Account is not Admin");
            setStateSnap({ open: true });
          }
        });

        // //motors
        // const collectionRefMotors = collection(db, "Motors");
        // const qMotors = query(collectionRefMotors, orderBy("Created"));
        // onSnapshot(qMotors, (snapshot) =>
        //   dispatch(
        //     setMotors(
        //       snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //     )
        //   )
        // );

        // users
        const collectionRefUsers = collection(db, "Users");
        const qUsers = query(collectionRefUsers, orderBy("Created"));
        onSnapshot(qUsers, (snapshot) =>
          dispatch(
            setUsers(
              snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          )
        );

        // medicines
        const collectionRefProducts = collection(db, "Medicines");
        const qProducts = query(collectionRefProducts, orderBy("Created"));
        onSnapshot(qProducts, (snapshot) =>
          dispatch(
            setMedicines(
              snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          )
        );

        // news and blogs
        const collectionRefNewsBlogs = collection(db, "News");
        const qNewsBlogs = query(collectionRefNewsBlogs, orderBy("Created"));
        onSnapshot(qNewsBlogs, (snapshot) =>
          dispatch(
            setNews(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
          )
        );

        // Patients
        const collectionRefPatients = collection(db, "Patients");
        const qPatients = query(collectionRefPatients, orderBy("Created"));
        onSnapshot(qPatients, (snapshot) =>
          dispatch(
            setPatients(
              snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          )
        );

        // History
        const collectionRefHistory = collection(db, "History");
        const qHistory = query(collectionRefHistory, orderBy("Created"));
        onSnapshot(qHistory, (snapshot) =>
          dispatch(
            setHistory(
              snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          )
        );

        // History
        const collectionRefAppointment = collection(db, "Appointment");
        const qAppointment = query(
          collectionRefAppointment,
          orderBy("Created")
        );
        onSnapshot(qAppointment, (snapshot) =>
          dispatch(
            setAppointment(
              snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          )
        );

        // //Orders
        // const collectionRefOrders = collection(db, "Orders");
        // const qOrders = query(collectionRefOrders, orderBy("CreatedAt"));
        // onSnapshot(qOrders, (snapshot) =>
        //   dispatch(
        //     setOrders(
        //       snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //     )
        //   )
        // );

        //  //Finance
        //  const collectionRefFinance = collection(db, "Finance");
        //  const qFinance = query(collectionRefFinance, orderBy("Created"));
        //  onSnapshot(qFinance, (snapshot) =>
        //    dispatch(
        //      setFinance(
        //        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //      )
        //    )
        //  );

        // setstate({ isAuth: true, isLoading: false });
      } else {
        setstate({ isAuth: false, isLoading: false });
      }
    });

    dispatch(getLang(), getTheme());
  }, [dispatch]);

  if (state.isLoading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={THEME}>
      <Snackbar
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={stateSnap.open}
        key={stateSnap.vertical + stateSnap.horizontal}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Your Account is not Admin
        </Alert>
      </Snackbar>

      <Box sx={{ display: "flex" }}>
        {state.isAuth === true ? <Sidebar /> : null}

        <Switch>
          <Route path="/" exact>
            <Redirect to="/login" />
          </Route>

          <PublicRoute
            restricted={true}
            component={Login}
            isAuth={state.isAuth}
            path="/login"
            exact
          />

          <PublicRoute
            restricted={true}
            component={ForgotPassword}
            isAuth={state.isAuth}
            path="/forgotpassword"
            exact
          />

          <PrivateRoute
            component={Dashboard}
            isAuth={state.isAuth}
            path="/dashboard"
            exact
          />

          <PrivateRoute
            component={CreatePatient}
            isAuth={state.isAuth}
            path="/createpatient"
            exact
          />

          <PrivateRoute
            component={ListPatient}
            isAuth={state.isAuth}
            path="/listpatient"
            exact
          />
          <PrivateRoute
            component={PatientRecord}
            isAuth={state.isAuth}
            path="/patientrecord/:id"
            exact
          />

          <PrivateRoute
            component={EditPatient}
            isAuth={state.isAuth}
            path="/editpatient"
            exact
          />

          <PrivateRoute
            component={ListUser}
            isAuth={state.isAuth}
            path="/listuser"
            exact
          />

          <PrivateRoute
            component={CreateUser}
            isAuth={state.isAuth}
            path="/createuser"
            exact
          />

          <PrivateRoute
            component={EditUser}
            isAuth={state.isAuth}
            path="/edituser"
            exact
          />

          <PrivateRoute
            component={ListAppointment}
            isAuth={state.isAuth}
            path="/listappointment"
            exact
          />
          <PrivateRoute
            component={CreateAppointment}
            isAuth={state.isAuth}
            path="/createappointment"
            exact
          />
          <PrivateRoute
            component={EditAppointment}
            isAuth={state.isAuth}
            path="/editappointment"
            exact
          />

          <PrivateRoute
            component={AcceptedAppoint}
            isAuth={state.isAuth}
            path="/acceptedappointment"
            exact
          />

          <PrivateRoute
            component={CancelAppoint}
            isAuth={state.isAuth}
            path="/cancelappointment"
            exact
          />

          <PrivateRoute
            component={DoneAppoint}
            isAuth={state.isAuth}
            path="/doneappointment"
            exact
          />

          <PrivateRoute
            component={ListMedicine}
            isAuth={state.isAuth}
            path="/listmedicine"
            exact
          />
          <PrivateRoute
            component={CreateMedicine}
            isAuth={state.isAuth}
            path="/createmedicine"
            exact
          />
          <PrivateRoute
            component={EditMedicine}
            isAuth={state.isAuth}
            path="/editmedicine"
            exact
          />

          <PrivateRoute
            component={Order}
            isAuth={state.isAuth}
            path="/order"
            exact
          />

          <PrivateRoute
            component={CreateNews}
            isAuth={state.isAuth}
            path="/createnews"
            exact
          />
          <PrivateRoute
            component={EditNews}
            isAuth={state.isAuth}
            path="/editnews"
            exact
          />

          <PrivateRoute
            component={ListNews}
            isAuth={state.isAuth}
            path="/listnews"
            exact
          />

          {/*report*/}
          <PrivateRoute
            component={Regular}
            isAuth={state.isAuth}
            path="/listregular"
            exact
          />

          <PrivateRoute
            component={Family}
            isAuth={state.isAuth}
            path="/listfamily"
            exact
          />

          <PrivateRoute
            component={Prenatal}
            isAuth={state.isAuth}
            path="/listprenatal"
            exact
          />

          <PrivateRoute
            component={Immunization}
            isAuth={state.isAuth}
            path="/listImmunization"
            exact
          />

          <PrivateRoute
            component={Availment}
            isAuth={state.isAuth}
            path="/listavailment"
            exact
          />

          <PrivateRoute
            component={Familylist}
            isAuth={state.isAuth}
            path="/familylistrecord/:id"
            exact
          />
          <PrivateRoute
            component={RegularList}
            isAuth={state.isAuth}
            path="/regularlistrecord/:id"
            exact
          />

          <PrivateRoute
            component={PrenatalList}
            isAuth={state.isAuth}
            path="/prenatallistrecord/:id"
            exact
          />

          <PrivateRoute
            component={ImmunizationList}
            isAuth={state.isAuth}
            path="/immunizationlistrecord/:id"
            exact
          />

          <PrivateRoute
            component={AvailmentList}
            isAuth={state.isAuth}
            path="/availmentlistrecord/:id"
            exact
          />

          <PrivateRoute
            component={History}
            isAuth={state.isAuth}
            path="/history"
            exact
          />

          <Route component={NotFound} />
        </Switch>
      </Box>
    </ThemeProvider>
  );
}
