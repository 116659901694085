import { filter } from 'lodash';
import { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box,
  Link,
  Breadcrumbs,
  Card,
  Paper,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  CardContent,
  IconButton,
} from '@mui/material';
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import MasksIcon from "@mui/icons-material/Masks";
import Chart from "react-apexcharts";
import { styled } from "@mui/material/styles"
import ReactToPrint from 'react-to-print';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

// components
import SearchNotFound from '../../components/SearchNotFound';
import {
  UserListHead,
  UserListToolbar,
  PatientMoreMenu,
} from '../../sections/user';
//
// import USERLIST from '../../_mocks_/user';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------

//date fns
import { format } from 'date-fns';
const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'FullName', label: 'Name', alignRight: false },
  { id: 'Created', label: 'Created', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.FullName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}


const style = {
  loop: {
    height: 17,
    width: 17,
    borderRadius: 10,
    color: "primary",
    animationName: 'blinker',
    animationDuration: '.7s',
    animationTimingFunction: 'linear',
    animationIterationCount:'infinite',
    // animation: "spin 2s linear infinite",
    "@keyframes blinker": {
      from: {opacity: 1},
        to: {opacity: 0}
    },
  },
  loop2: {
    height: 17,
    width: 17,
    borderRadius: 10,
    color: "primary",
    animationName: 'blinker',
    animationDuration: '1.5s',
    animationTimingFunction: 'linear',
    animationIterationCount:'infinite',
    // animation: "spin 2s linear infinite",
    "@keyframes blinker": {
      from: {opacity: 1},
        to: {opacity: 0}
    },
  },
  loop3: {
    height: 17,
    width: 17,
    borderRadius: 10,
    color: "primary",
    animationName: 'blinker',
    animationDuration: '2s',
    animationTimingFunction: 'linear',
    animationIterationCount:'infinite',
    // animation: "spin 2s linear infinite",
    "@keyframes blinker": {
      from: {opacity: 1},
        to: {opacity: 0}
    },
  },
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: 20,
  width: "100%",
}));



export default function Prenatal(props) {
  const ui = useSelector((state) => state.ui);
  const user = useSelector((state) => state.user);
  const USERLIST = user.patients.filter(
    (patients) => patients.Category === "Immunization"
    );
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('FullName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { history } = props;
  const componentRef = useRef();

  const uniqueIds = [];
  const newUSERLIST = USERLIST.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.FullName);
    if (!isDuplicate) {
      uniqueIds.push(element.FullName);
      return true;
    }
    return false;
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = newUSERLIST.map((n) => n.FullName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newUSERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    newUSERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;


  const [optionsArea] = useState({
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "Month",
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      labels: {
        style: {
          colors: ui.isDarkMode === true ? "gray" : "gray",
        },
      },
    },
  
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "top",
      horizontalAlign: "right",
      floating: false,
      fontSize: "14px",
      fontFamily: "Helvetica, Arial",
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: ui.isDarkMode === true ? "black" : "white",
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
  
    grid: {
      borderColor: "#90A4AE",
      strokeDashArray: 10,
  
      yaxis: {
        lines: {
          show: false,
        },
  
        labels: {
          style: {
            colors: ui.isDarkMode === true ? "gray" : "gray",
          },
        },
      },
    },
  
    colors: ["#E91E63", "#5DDC9A"],
    tooltip: {
      theme: ui.isDarkMode === true ? "light" : "dark",
    },
  });

  const [seriesArea] = useState([
    {
      name: "Total Immunization Patient",
      data: [user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "01/2022").length, 
      user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "02/2022").length, 
      user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "03/2022").length, 
      user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "04/2022").length, 
      user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "05/2022").length, 
      user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "06/2022").length, 
      user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "07/2022").length, 
      user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "08/2022").length, 
      user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "09/2022").length, 
      user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "10/2022").length, 
      user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "11/2022").length,
      user.patients.filter((patient) =>  format(new Date((patient.Category === "Immunization" && patient.Created.seconds*1000)), 'MM/yyyy') === "12/2022").length],
    },
  ]);

  return (
    <Container sx={{ mt: 12, mb: 5 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            <Box gridColumn="span 9" direction="column" alignItems="center">
              <Typography variant="h4" gutterBottom>
              Immunization Report
              </Typography>
              <Breadcrumbs
                sx={{ ml: 0.5 }}
                separator={
                  <Box
                    sx={{
                      width: 4,
                      height: 4,
                      bgcolor: 'gray',
                      borderRadius: '50%',
                      mx: 1,
                    }}
                  />
                }
              >
                <Link
                  component={RouterLink}
                  underline="hover"
                  color="inherit"
                  to="/listpatient"
                >
                  <Typography color="text.primary" variant="body2">
                    Report Chart
                  </Typography>
                </Link>
              </Breadcrumbs>
            </Box>
            <Box
              gridColumn="span 3"
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
            >
              
              <Box gridColumn="span 3">
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="contained"
                      startIcon={<LocalPrintshopOutlinedIcon />}
                    >
                      Print
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
              </Box>
            </Box>
          </Box>
        </Stack>
      <Grid  ref={componentRef} sx={{margin: 2}}>
      <Grid container spacing={1}>
        <Grid item xs={16} sm={12} md={8} lg={6}>
          <Item >
            <Card sx={{ display: "flex", boxShadow: 0, paddingTop: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "column", height: 390 }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography variant="subtitle1">Total Immunization Patient</Typography>
                  <Typography
                    variant="h4"
                    color="text.primary"
                    sx={{ fontWeight: 700, marginLeft: 22, marginTop: 16, fontSize: 55 }}
                  >
                    {
                      user.patients.filter(
                        (patients) => patients.Category === "Prenatal"
                        ).length
                    }
                  </Typography>
                </CardContent>
                <Box
                  sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1, marginLeft: 15 }}
                >
                  <IconButton aria-label="play/pause">
                    <QueryStatsIcon color="primary" sx={style.loop} />
                  </IconButton>
                  <Typography sx={{ fontSize: 12 }}>
                    {/* +2.6% than last week */}
                    Live Tracking
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  width: 200,
                }}
              >
                {/* <Chart options={optionsLine} series={series} type="line" /> */}
                <MasksIcon color="primary" sx={{ fontSize: 120 }} />
              </Box>
            </Card>
          </Item>
        </Grid>


        <Grid item xs={16} sm={12} md={8} lg={6} paddingBottom="10px">
          <Item>
            <Typography
              color="textPrimary"
              sx={{ textAlign: "left", ml: 1, fontWeight: 600, my: 1 }}
            >
              Monthly Immunization Patients
            </Typography>
            <Chart
              options={optionsArea}
              series={seriesArea}
              type="area"
              height={350}
              width={500}
            />
          </Item>
        </Grid>
      </Grid>

      <Typography sx={{fontSize: 32}} color="gray" variant="body2">
                  List
      </Typography>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <TableContainer  sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={newUSERLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      id,
                      UserName,
                      Created,
                      FullName,
                    } = row;
                    const isItemSelected = selected.indexOf(UserName) !== -1;

                    return (
                      <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            history.push(`/immunizationlistrecord/${id}`)
                          }
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selected.includes(FullName)}
                              onChange={(event) => handleClick(event, FullName)}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {id}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={UserName} src={Image} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {FullName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {format(
                              new Date(Created.seconds * 1000),
                              'MM/dd/yyyy'
                            )}{' '}
                          </TableCell>
                        </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={newUSERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Grid>
      </Container>
    </Container>
  );
}